import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';
import Carousel from 'react-multi-carousel';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import SEO from '../components/seo';
import Layout from '../components/layout';
import {
  H1White, H2White, H2Black, H4Black, PWhite,
} from '../components/typography';
import 'react-multi-carousel/lib/styles.css';
import TeamCard from '../components/teamCard';
import ProjectIndex from '../components/projectIndex';
import VideoLightBox from '../components/videoLightBox';
import Arrow from '../images/arrow-right.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 6000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-bottom: 50px;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledCustomArrowRight = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  right: calc(2.4% + 1px);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill .5s ease;
  }
`;

const StyledCustomArrowLeft = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  left: calc(2.4% + 1px);
  transform: rotate(-180deg);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill .5s ease;
  }
`;

const StyledH1White = styled(H1White)`
  margin-top: 49px;
  margin-bottom: 30px;

  @media (min-width: 64em) {
    margin-top: 49px;
    margin-bottom: 67px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
`;

const Button = styled.button`
  color: var(--white);
  width: 182px;
  height: 43px;
  border: 1px solid var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 0.32px;
  line-height: 1.125rem;
`;

const Table = styled.table`
  color: var(--white);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.89px;
  line-height: 1.125rem;
  text-align: left;
  margin-bottom: 42px;
`;

const TH = styled.th`
  min-width: 135px;
  padding-bottom: 8px;
`;

const THWithPadding = styled.th`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const TR = styled.tr`
  border-top: 1px solid var(--white);
`;

const StyledPWhite = styled(PWhite)`
  margin-bottom: 50px;

  @media (min-width: 64em) {
    margin-bottom: 104px;
  }
`;

const StyledGridWhite = styled(Grid)`
  background-color: var(--white);
  max-width: 89rem;
  width: 100%;
`;

const StyleH2Black = styled(H2Black)`
  margin-top: 43px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 46px;

  &:hover .overlay {
    opacity: 0.9;
  }

  &:hover .title {
    color: var(--blue);
    transition: color .5s ease;
  }
`;

const OverlayWrapper = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  @media (min-width: 64em) {
    justify-content: flex-end;
    margin-top: 69px;
    margin-bottom: 67px;
    margin-left: 20px;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  background-color: var(--white);
`;

const StyledSliderImage = styled(Img)`
  width: 100%;
  height: 90vh
`;

const StyledCarousel = styled(Carousel)`
  z-index: 9;
  margin-top: -150px;
  height: 90vh;
`;

const iconBase = css`
  fill: var(--white);
  margin-right: 20px;
  font-size: 1.75rem;

  @media (min-width: 64em) {
    margin-left: 20px;
  }

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill .5s ease;
  }
`;

const StyledImageCaption = styled.div`
  background-color: #1A1A1A;
  bottom: 50px;
  color: var(--white);
  left: 30px;
  padding: 2px 5px;
  position: absolute;
  z-index: 9;
`;

const StyledPdfIcon = styled(PictureAsPdfOutlinedIcon)`
  && {
    ${iconBase}
  }
`;

const StyledMenuIcon = styled(MenuOutlinedIcon)`
  && {
    ${iconBase}
  }
`;

const StyledVideoIcon = styled(VideocamOutlinedIcon)`
  && {
    ${iconBase}
    cursor: pointer;
  }
`;

const Project = ({ data, onClick }) => {
  const [showIndex, setShowIndex] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const project = data.datoCmsProject;
  const allProjects = data.allDatoCmsProject.edges.filter(({
    node: p,
  }, idx) => p.category.name === project.category.name && idx < 6);

  const preventScroll = () => {
    document.body.classList.add('prevent-scroll');
  };

  return (
    <Layout>
      <ProjectIndex showIndex={showIndex} setShowIndex={setShowIndex} />
      {project.video !== null
        && (
          <VideoLightBox
            video={project.video.url}
            showVideo={showVideo}
            setShowVideo={setShowVideo}
          />
        )}
      <SEO title={project.title} />
      <StyledCarousel
        responsive={responsive}
        infinite
        customRightArrow={<StyledCustomArrowRight onClick={() => onClick()} />}
        customLeftArrow={<StyledCustomArrowLeft onClick={() => onClick()} />}
      >
        {project.carousel.map(({ fluid, title }) => (
          <div key={fluid}>
            <StyledSliderImage fluid={fluid} key={fluid} alt={fluid.title} />
            <StyledImageCaption>{title}</StyledImageCaption>
          </div>
        ))}
      </StyledCarousel>
      <StyledGrid fluid>
        <Row>
          <Col lg={8} lgOffset={1} md={9} sm={9} xs={12}>
            <StyledH1White>{project.title}</StyledH1White>
          </Col>
          <Col lg={2} lgOffset={1} md={3} sm={3} xs={12}>
            <IconContainer>
              {project.pdf !== null
                && (
                  <a href={project.pdf.url} target="_blank" rel="noopener noreferrer">
                    <StyledPdfIcon />
                  </a>
                )}
              {project.video !== null
                && (
                  <StyledVideoIcon
                    onClick={(e) => {
                      e.preventDefault();
                      setShowVideo(!showVideo);
                    }}
                  />
                )}
              <Link to="/projects">
                <StyledMenuIcon
                  onClick={(e) => {
                    e.preventDefault();
                    preventScroll();
                    setShowIndex(!showIndex);
                  }}
                />
              </Link>
            </IconContainer>
          </Col>
        </Row>
        <StyledRow>
          <Col lg={6} lgOffset={1} md={8} sm={6} xs={12}>
            <StyledPWhite>{project.description}</StyledPWhite>
          </Col>
          <Col xs={12} md={4} sm={6} lgOffset={1} lg={4}>
            <Table>
              <thead>
                <tr>
                  <TH>Year</TH>
                  <TH>{project.started}</TH>
                </tr>
                <TR>
                  <THWithPadding>Client</THWithPadding>
                  <THWithPadding>{project.client}</THWithPadding>
                </TR>
                <TR>
                  <THWithPadding>Cost</THWithPadding>
                  <THWithPadding>{project.cost}</THWithPadding>
                </TR>
                <TR>
                  <THWithPadding>Completed</THWithPadding>
                  <THWithPadding>{project.completed}</THWithPadding>
                </TR>
              </thead>
            </Table>
            <H2White>Team</H2White>
            {project.team.map(({
              id, avatar, name, title, slug,
            }) => (
              <TeamCard
                name={name}
                title={title}
                avatar={avatar}
                slug={slug}
                key={id}
                arrowRight={data.arrowRight}
              />
            ))}
          </Col>
        </StyledRow>
      </StyledGrid>
      {allProjects.length >= 1 && (
        <GridContainer>
          <StyledGridWhite fluid>
            <Row>
              <Col xs={12}>
                <StyleH2Black>Related Projects</StyleH2Black>
              </Col>
              {allProjects.map(({ node: p }) => (
                <React.Fragment key={p.id}>
                  <Col lg={4} md={4} sm={4} xs={12}>
                    <Link to={`/projects/${p.slug}`}>
                      <Container>
                        <OverlayWrapper>
                          <Img fluid={{ ...p.cover.fluid, aspectRatio: 4 / 3 }} className="project-image" />
                          <Overlay className="overlay">
                            <Button type="button">Read more</Button>
                          </Overlay>
                        </OverlayWrapper>
                        <H4Black className="title">{p.title}</H4Black>
                      </Container>
                    </Link>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </StyledGridWhite>
        </GridContainer>
      )}
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query ProjectQuery($slug: String!) {
    datoCmsProject(slug: { eq: $slug }) {
      id
      title
      slug
      carousel {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
      }
      pdf {
        url
      }
      video {
        url
      }
      client
      category {
        name
      }
      cost
      description
      size
      completed
      started
      team {
        id
        name
        slug
        title
        avatar {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    allDatoCmsProject(
      limit: 6,
      sort: {
        fields: [meta___publishedAt],
        order: DESC
      },
      filter: {
        meta: {isValid: {eq: true}, status: {eq: "published"}},
        slug: { ne: $slug },
      }
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            name
          }
          meta {
            publishedAt
            isValid
            status
          }
          cover {
            fluid(maxHeight: 375) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
    arrowRight: file(relativePath: { eq: "arrow-right.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 9, height: 10) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;

Project.propTypes = {
  data: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
};

Project.defaultProps = {
  onClick: () => {},
};
