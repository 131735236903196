import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const VideoWrapper = styled.div`
  background-color: var(--white);
  position: fixed;
  top: 50%;
  overflow-x: auto;
  left: 50%;
  transition: all .5s ease;
  transform: translate(-50%, -40%);
  width: 100%;
  z-index: 999;
  padding: 30px;
  visibility: hidden;
  opacity: 0;

  @media (min-width: 64em) {
    padding: 50px;
    width: 50%;
  }

  &.active {
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
  }
`;

const StyledButton = styled.button`
  font-size: 32px;
  position: absolute;
  right: 0;
  top: 0;
  transition: color .5s ease;

  @media (min-width: 64em) {
    right: 10px;
    top: 10px;
  }

  &:hover,
  &:focus {
    color: var(--blue);
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  && {
    cursor: pointer;
    font-size: 32px;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
`;

function VideoLightBox({ video, showVideo, setShowVideo }) {
  return (
    <VideoWrapper className={`${showVideo ? 'active' : 'hidden'}`}>
      <StyledButton
        onClick={(e) => {
          e.preventDefault();
          setShowVideo(!showVideo);
        }}
      >
        <StyledCloseIcon />
      </StyledButton>
      <StyledVideo
        controls
        src={video}
      >

        <track
          default
          kind="captions"
          srcLang="en"
        />

        Sorry, your browser doesn&apos;t support embedded videos.
      </StyledVideo>
    </VideoWrapper>
  );
}

VideoLightBox.propTypes = {
  showVideo: PropTypes.bool.isRequired,
  setShowVideo: PropTypes.func.isRequired,
  video: PropTypes.string.isRequired,
};

export default VideoLightBox;
