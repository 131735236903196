import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  H3White, PSmallWhite,
} from './typography';

const CardContainer = styled.div`
  width: 100%;
  height: 168px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const ImgContainer = styled.div`
  width: 168px;
  height: 168px;
  margin-right: 20px;
`;

const StyledBackroundImage = styled(BackgroundImage)`
  height: 168px;
`;

const StyledH3White = styled(H3White)`
  margin-bottom: 16px;
`;

const StyledH3SmallWhite = styled(PSmallWhite)`
  text-transform: capitalize;
  margin-bottom: 8px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowRight = styled(Img)`
  margin-left: 8px;
`;

const PSmallWhiteAsDiv = styled.div`
  color: var(--white);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.89px;
  line-height: 1.125rem;
`;

const TeamCard = ({
  name, title, avatar, slug, arrowRight,
}) => (
  <CardContainer>
    <ImgContainer>
      <StyledBackroundImage fluid={avatar.fluid} />
    </ImgContainer>
    <InnerContainer>
      <StyledH3SmallWhite>{`Project ${title}:`}</StyledH3SmallWhite>
      <StyledH3White>{name}</StyledH3White>
      <PSmallWhiteAsDiv>
        <Link to={`/team/${slug}`}>
          See profile
          <ArrowRight fixed={arrowRight.childImageSharp.fixed} />
        </Link>
      </PSmallWhiteAsDiv>
    </InnerContainer>
  </CardContainer>
);

TeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  avatar: PropTypes.shape().isRequired,
  slug: PropTypes.string.isRequired,
  arrowRight: PropTypes.shape().isRequired,
};

export default TeamCard;
